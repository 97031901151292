import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell as _TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box, Button } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import Tooltip from 'components/popups/Tooltip';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



export default function TapsByEvent() {

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;


    useEffect(() => {
        fetchExpresso(`/admin/reports/taps_by_event?tz=${moment.tz.guess()}&start=${moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')}&end=${moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, []);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load taps</Typography>
                </Status>
            </Root>
        );
    }


    return (
        <Root>

            <CardHeader
                title='Taps by Event'
                action={(
                    <Box>
                        <Button style={{textDecoration: 'underline'}}>Today</Button>
                        {/* <Button style={range === 'day' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('day')}>Today</Button>
                        <Button style={range === 'month' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('month')}>This Month</Button>
                        <Button style={range === 'year' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('year')}>This Year</Button> */}
                    </Box>
                )}
            />

            <CardContent>

                <TableContainer component={Box}>

                    <Table size='small'>

                        <TableHead>

                            <TableRow>
                                <TableCellBold>EVENT</TableCellBold>
                                <TableCellBold align='right'>
                                    <Box height={'100%'} display='flex' alignItems={'center'} justifyContent={'flex-end'}>
                                        <OrderIcon />
                                    </Box>
                                </TableCellBold>
                                <TableCellBold align='right'>
                                    <Box height={'100%'} display='flex' alignItems={'center'} justifyContent={'flex-end'}>
                                        <CardIcon />
                                    </Box>
                                </TableCellBold>
                                <TableCellBold align='right'>
                                    <Box height={'100%'} display='flex' alignItems={'center'} justifyContent={'flex-end'}>
                                        <CashIcon />
                                    </Box>
                                </TableCellBold>
                                <TableCellBold align='right'>WHEN</TableCellBold>
                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {data.map((r, index) => (
                                <TableRow key={r.event_id}>
                                    <Tooltip message={r.event_title}>
                                        <EventTitleCell><EventTitleText noWrap variant='body2'>{r.event_title}</EventTitleText></EventTitleCell>
                                    </Tooltip>
                                    <TableCell align='right'>{r['Order Count']}</TableCell>
                                    <TableCell align='right'>{r['Card Qty']}</TableCell>
                                    <TableCell align='right'>{r['Cash Qty']}</TableCell>
                                    <TableCell align='right'>{moment(r.last_order).fromNow()}</TableCell>
                                </TableRow>
                            ))}

                            {data.length === 0 && (
                                <TableRow>
                                    <TableCell align='center' colSpan={5}>
                                        <Typography variant='subtitle2'>No data found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}

                        </TableBody>

                    </Table>

                </TableContainer>

            </CardContent>

        </Root>
    )
}

const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TableCell = styled(_TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap'
}));

const TableCellBold = styled(_TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
}));

const EventTitleCell = styled(_TableCell)(({ theme }) => ({
    paddingRight: 0,
    maxWidth: 320,
    [theme.breakpoints.down('lg')]: {
        maxWidth: 160
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: 100
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: 220
    },
}));

const EventTitleText = styled(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

const OrderIcon = styled(ReceiptIcon)(({ theme }) => ({
    height: 18,
    width: 18
}));

const CardIcon = styled(CreditCardIcon)(({ theme }) => ({
    height: 18,
    width: 18
}));

const CashIcon = styled(LocalAtmIcon)(({ theme }) => ({
    height: 18,
    width: 18
}));